* {
  box-sizing: border-box;
  background: #F5F7FB;
  position: relative;
  /* 
  resize: none; */
}

body {
  margin:0px;
  height:100%;
  user-select: none;
}

.start {
  display: flex;
  width: 100vw;
  height: 100vh;
  min-width: 550px;
  min-height: 660px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}



.unselected.unchecked {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  margin: 10px;
  width: auto;
  height: 30px;
  border-radius: 7.94239px;
  border: 0.771045px solid #4D5B9E;
  border-radius: 7.71045px;
  
  background: transparent;
  color: #293264;
}

.unselected {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  margin: 10px;
  width: auto;
  height: 30px;
  border-radius: 7.94239px;
  border: 0.771045px solid #4D5B9E;
  border-radius: 7.71045px;
  
  color: #293264;
  
}
.unselected.checked {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  margin: 10px;
  width: auto;
  height: 30px;
  border-radius: 7.94239px;
  border: 0.771045px solid #4D5B9E;
  border-radius: 7.71045px;
  
  color: #293264;
  opacity: 0.5;
}

.correct--answer.checked {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  margin: 10px;
  width: auto;
  height: 30px;
  
  border-radius: 7.94239px;
  border: 0.771045px solid #4D5B9E;
  border-radius: 7.71045px;
  border-style: none;
  
  background: #94D7A2;
  color: #293264;
  opacity: 1;
  
}

.selected.unchecked {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  margin: 10px;
  width: auto;
  height: 30px;
  border-radius: 7.94239px;
  border: 0.771045px solid #4D5B9E;
  border-radius: 7.71045px;
  
  border-style: none;
  color: #293264;
  background: #D6DBF5;

}

.incorrect--answer.selected.checked {
  display: inline-block;
  justify-content: center;
  align-items: center;
  padding: 5px 10px 5px 10px;
  margin: 10px;
  width: auto;
  height: 30px;
  border-radius: 7.94239px;
  border: 0.771045px solid #4D5B9E;
  border-radius: 7.71045px;
  
  color: #293264;
  background: #F8BCBC;
  opacity: 0.5;
}



.start--header {
  margin: 0px 0px 10px 0px;
  
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  font-size: 31.25px;
  line-height: 37px;
  
  color: #293264;
}

.start--description {
  margin: 0px 0px 40px 0px;
  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 19px;
  
  color: #293264;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  
  color: #F5F7FB;
  background: #4D5B9E;

  user-select: none;
}

.game--button {
  width: 193px;
  height: 52px;
  border-radius: 15px;
  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
}

.game--button:active {
  box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
}

.question--area--container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.question--area {
  max-width: 800px;
  
}

.question--area--bottom {
  display: flex;
  padding: 0% 0% 0% 0%;
}

.question {
  padding: 10px;
}

.you--scored {
  padding: 0% 0% 0% 30px;
}